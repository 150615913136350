import * as React from "react"
import Popover from "@mui/material/Popover"
import Button from "@mui/material/Button"
import SchoolIcon from "@mui/icons-material/School"
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material"
import { useTranslation } from "react-i18next"

import { useRouter } from "next/router"
import StateSelect from "./StateSelect"
import RoundedCard from "./RoundedCard"

const DriversEdMenuPopover = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [selectedState, setSelectedState] = React.useState<string | null>(null)
  const isOpen = Boolean(anchorEl)
  const { t } = useTranslation()
  const router = useRouter()

  const openPopover = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const closePopover = (): void => {
    setSelectedState("")
    setAnchorEl(null)
  }

  const submitForm = (): void => {
    router.push(`/courses?state=${selectedState?.toLowerCase()}`)
  }

  return (
    <>
      <Button
        variant="contained"
        onClick={openPopover}
        endIcon={isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        sx={{
          fontWeight: 700,
          padding: "20px",
          height: "min-content",
          width: "max-content",
        }}
        data-cy="drivers-ed-cta"
      >
        <SchoolIcon style={{ marginRight: 10 }} />
        {t("Drivers Ed")}
      </Button>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <RoundedCard style={{ minWidth: 260 }}>
          <StateSelect onChange={(id) => setSelectedState(id ?? null)} />
          <br />
          <Button
            variant="contained"
            onClick={submitForm}
            style={{ marginTop: 15 }}
            disabled={!selectedState}
          >
            {t("View Course")}
          </Button>
        </RoundedCard>
      </Popover>
    </>
  )
}

export default DriversEdMenuPopover
