import React from "react"
import Paper from "@mui/material/Paper"

interface RoundedCardProps {
  children: React.ReactNode
  style?: React.CSSProperties
}

const RoundedCard = ({ children, style }: RoundedCardProps): JSX.Element => (
  <Paper
    sx={{
      bgcolor: "background.paper",
      borderRadius: "5px",
      padding: "25px",
      margin: "0 auto",
      boxShadow: "5px 5px 25px 0px rgba(46, 61, 73, 0.2)",
      ...style,
    }}
  >
    {children}
  </Paper>
)

export default RoundedCard
